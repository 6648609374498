@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

@media only screen and (max-width: 10000px) {
  .trabalhosContainer {
    display: flex;
    align-items: flex-start;
    margin-left: 250px;
    margin-right: 250px;
    justify-content: space-between;
  }

  .detalhesContainer {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 35px;
    flex: 50%;
  }

  .detalhesContainer h1 {
    font-family: "DM Sans";
    color: #000;
    letter-spacing: -1.2px;
  }

  .detalhesContainer hr {
    border: 2px solid #508ce4;
    align-self: left;
  }

  .contrataPessoas {
    width: 300px;
    background-color: #fff;
    position: absolute;
    right: 200px;
    border-radius: 5px;
    margin-top: 15px;
    padding-bottom: 40px;
  }

  .contrataPessoas header {
    background-color: #508ce4;
    padding: 1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .contrataPessoas p {
    padding-left: 15px;
    padding-right: 15px;
    font-family: "Avenir Next";
  }

  .tituloContrataPessoas {
    font-family: "Avenir Next";
    color: #fff;
    font-weight: bold;
    padding-left: 15px;
    font-size: 15px;
    text-transform: uppercase;
  }

  .btnPostJob {
    box-shadow: 2px 2px 10px #508ce4;
    background-color: #508ce4;
    font-family: "Avenir Next";
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    text-decoration: none;
    margin-left: 15px;
  }

  .Select {
    background-color: #fff;
    margin: 15px;
  }

  .barDir {
    background-color: #fafafa;
    flex: 10%;
    margin-left: 20px;
    border-radius: 35px;
    padding-bottom: 10px;
  }

  .barDir header {
    padding: 25px 35px 35px 35px;
  }

  .barDir header h1 {
    color: #000;
    letter-spacing: -0.5px;
    font-family: "DM Sans";
    font-size: 28px;
    line-height: 95%;
  }

  .barDir p {
    color: #000;
    letter-spacing: -0.5px;
    font-family: "DM Sans";
    font-size: 16px;
  }

  .popup {
    left: 0;
    right: 0;
    margin-left: 200px;
    margin-right: 200px;
    margin-top: 120px;
    background-color: #508ce4;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
  }

  .criticaText {
    color: #fff;
    text-align: center;
    font-size: 21px;
    padding-top: 25px;
  }

  .fecharBtn {
    background-color: transparent;
    color: #fff;
    font-size: 18px;
    margin-top: -70px;
    padding-bottom: 30px;
    padding-right: 15px;
    display: block;
    margin-left: auto;
  }

  .CriarTrabalho {
    text-decoration: none;
    color: #fff;
    border-radius: 5px;
    background-color: #508ce4;
    font-family: "DM Sans";
    display: block;
    text-align: center;
    margin-top: 25px;
    padding: 10px 15px;
    border-radius: 100px;
    letter-spacing: -0.5px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

  .CriarTrabalho:hover {
    opacity: 0.8;
  }
}

@media only screen and (max-width: 1250px) {
  .popup {
    left: 0;
    right: 0;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 120px;
    background-color: #508ce4;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
  }

  .trabalhosContainer {
    display: block;
    align-items: flex-start;
    margin-left: 25px;
    margin-right: 25px;
    justify-content: space-between;
  }

  .barDir {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 25px;
  }
}

@media only screen and (max-width: 475px) {
  .popup {
    left: 0;
    right: 0;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 120px;
    background-color: #508ce4;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
  }

  .popup p {
    font-size: 17px;
  }

  .popup button {
    font-size: 12px;
  }

  .trabalhosContainer {
    display: block;
    align-items: flex-start;
    margin-left: 15px;
    margin-right: 15px;
    justify-content: space-between;
  }
}
