@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

body {
  background-color: #fff;
}

nav {
  width: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10000;
  border: 1px solid #eee;
}

nav img {
  padding-left: 250px;
  font-size: 1.3rem;
}

nav ul {
  display: flex;
  gap: 2rem;
  padding-right: 250px;
  align-items: center;
}

nav ul li {
  list-style: none;
  color: #000;
  font-weight: 500;
  font-family: "DM Sans";
  letter-spacing: -0.5px;
  font-size: 16px;
}

.displayNone {
  display: none;
}
@media screen and (max-width: 1480px) {
  nav img {
    padding-left: 35px;
  }
  nav ul {
    padding-right: 35px;
  }
}
@media screen and (max-width: 1050px) {
  .HamMenu {
    padding-right: 25px;
  }

  .navOpen {
    top: 0;
    position: fixed;
    width: 100vw;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(15px);
    font-family: "DM Sans";
    z-index: 1000;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: height 0.3s ease; /* Add this line for the transition effect */
  }
  .navOpen ul,
  .navClose ul {
    margin: 0;
    padding: 0;
  }
  .navOpen ul a li,
  .navOpen ul li,
  .navClose ul a li,
  .navClose ul li {
    font-size: 18px;
    margin: 30px 0px;
    padding: 0;
    color: #000;
    list-style: none;
  }
}

@media screen and (max-width: 475px) {
  nav img {
    padding-left: 15px;
  }
  .HamMenu {
    padding-right: 15px;
  }
}
