@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

@media only screen and (max-width: 10000px) {
  .divTotal {
    margin-left: 250px;
    margin-right: 250px;
    margin-top: 120px;
  }

  .divTotal h1 {
    font-size: 35px;
    padding-left: 5px;
    letter-spacing: -0.5px;
    line-height: 110%;
    font-weight: 500;
  }

  .divTotal h1 b {
    font-size: 35px;
  }

  .divTotalFilho {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .btnVer {
    background-color: #508ce4;
    font-family: "DM Sans";
    font-size: 16px;
    letter-spacing: -0.5px;
    border-radius: 100px;
    padding: 15px 25px;
    margin-left: 0;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

  .btnVer:hover {
    opacity: 0.7;
  }

  .tituloDivTotal {
    font-size: 28px;
    font-family: "DM Sans";
    margin-bottom: 5px;
  }

  .subtituloDivTotal {
    font-family: "DM Sans";
    background-color: #f9f9f9;
    border: 0.5px solid #ddd;
    padding: 25px;
    letter-spacing: 0.5px;
    border-radius: 5px;
    text-align: center;
    color: #000;
  }

  .passos {
    display: flex;
    justify-content: space-between;
    padding-right: 35px;
    margin-top: 25px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: -10px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border: 1px solid #eee;
  }

  .passo {
    list-style: none;
    color: #333;
    font-family: "DM Sans";
    letter-spacing: -0.5px;
    text-transform: uppercase;
    font-size: 18px;
    text-align: center;
    padding: 0;
    border-radius: 100px;
  }

  .divCabecalho {
    margin: 0 200px 0px 200px;
    background-color: #fff;
    box-shadow: 0px -50px 25px rgba(0, 0, 0, 0.1);
    padding-top: 70px;
    border-bottom-left-radius: 15px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .divCabecalho img {
    border-bottom-left-radius: 15px;
    height: 130%;
  }

  .divCabecalho div {
    width: 50%;
  }

  .workers_side {
    background-image: url("../../src/imgs/workers.jpg");
    background-size: 53%;
    background-attachment: fixed;
    height: 500px;
    border-bottom-left-radius: 15px;
    width: 60%;
    margin-right: 50px;
  }

  .title {
    font-size: 240%;
    font-family: "DM Sans";
    width: 80%;
    color: #000;
    font-weight: bold;
  }

  .titleSend {
    font-family: "DM Sans";
    letter-spacing: -0.8px;
    font-size: 30px;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .subtitle {
    font-size: 120%;
    padding-bottom: 30px;
    margin-top: -10px;
    text-decoration: underline;
    text-decoration-color: rgba(0, 61, 250, 0.05);
    text-underline-offset: 12px;
    text-decoration-thickness: 10px;
    /* font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif; */
    font-family: "Avenir Next";
    width: 70%;
    color: #508ce4;
    font-weight: 500;
  }

  .btnRegister {
    color: #fff;
    border-radius: 10px;
    box-shadow: 0px 10px 35px #508ce4;
    list-style-type: none;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    background-color: #508ce4;
    padding-bottom: 2px;
    z-index: 10;
  }

  .btnRegister h3 {
    font-size: 18px;
    font-family: "DM Sans";
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }

  .btnRegister:hover,
  .btnPostJob:hover {
    opacity: 0.5;
    transition: 0.3s;
    cursor: pointer;
  }

  .divAboutUs {
    margin-left: 200px;
    margin-right: 200px;
    padding: 50px;
    z-index: 10;
    border-radius: 5px;
    margin-top: 50px;
    text-align: end;
    font-size: 18px;
    font-family: "DM Sans";
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .divAboutUs div {
    text-align: center;
    width: 29%;
    background-color: #fff;
    border-radius: 15px;
    padding: 15px;
    padding-bottom: 50px;
  }

  .divAboutUs h1 {
    font-size: 1.7rem;
    margin-left: 15px;
    color: #508ce4;
    margin-right: 15px;
  }

  .divAboutUs p {
    font-size: 1rem;
    color: #000;
    margin-top: -10px;
  }

  .titles {
    align-self: center;
    text-align: center;
    color: #333;
    font-family: "DM Sans";
    margin-top: 10px;
    padding-top: 70px;
    margin-bottom: -50px;
  }

  .zonaComecar {
    background-color: #f2f2f2;
  }

  .verComoFuncionaBtn {
    text-align: center;
    background-color: #508ce4;
    padding: 10px;
    padding-bottom: 15px;
  }

  .verComoFuncionaBtn h1 {
    background-color: #508ce4;
    color: #fff;
    font-size: 180%;
    padding-top: 30px;
    font-family: "DM Sans";
  }

  .verComoFuncionaBtn p {
    background-color: #508ce4;
    color: #fff;
    font-size: 16px;
    font-family: "Avenir Next";
    margin-left: 25rem;
    padding-bottom: 30px;
    margin-right: 25rem;
  }

  .subtitleSemDots {
    font-size: 18px;
    font-family: "DM Sans";
    color: #508ce4;
    font-weight: 400;
  }

  .zonaApply {
    margin-left: 200px;
    margin-right: 200px;
  }

  .zonaApplyEsq {
    width: 50%;
  }

  .zonaApplyDir {
    width: 50%;
  }

  .divCabecalho2 {
    margin: 0 200px 0px 200px;
    background-color: #fff;
    padding-left: 50px;
    padding-top: 10px;
    border-bottom-right-radius: 15px;
    opacity: 1;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
  }

  .divCabecalho2 img {
    border-bottom-right-radius: 5px;
  }

  .workers_side2 {
    background-image: url("../../src/imgs/handshake.png");
    background-size: 15%;
    height: 500px;
    border-radius: 15px;
    width: 60%;
  }

  .workers_side2 p {
    color: #000;
  }

  .btnApply {
    background-color: #508ce4;
    color: #fff;
    border-radius: 10px;
    box-shadow: 2px 2px 10px #508ce4;
    list-style-type: none;
    padding-left: 10px;
    padding-right: 10px;
    width: 300px;
    padding-top: 2px;
    padding-bottom: 2px;
    z-index: 10;
  }

  .btnApply:hover {
    opacity: 0.7;
    transform: 0.3s ease;
  }

  .btnApply h3 {
    font-size: 18px;
    font-family: "DM Sans";
    text-align: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 1480px) {
  .divTotal {
    margin-left: 35px;
    margin-right: 35px;
  }
}

@media only screen and (max-width: 1200px) {
  .divTotal {
    margin-left: 25px;
    margin-right: 25px;
  }
}

@media only screen and (max-width: 700px) {
  .divTotalFilho {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
}

@media only screen and (max-width: 475px) {
  .divTotal {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 95px;
  }

  .divTotal h1 {
    font-size: 28px;
  }

  .divTotal h1 b {
    font-size: 28px;
  }

  .subtituloDivTotal {
    padding: 15px;
    font-size: 14px;
  }
}
