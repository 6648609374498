@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

@media only screen and (max-width: 10000px) {
  .workerPage {
    margin-left: 250px;
    margin-right: 250px;
    margin-top: 120px;
    border-radius: 35px;
    padding: 20px;
    font-family: "DM Sans";
    background-color: #f9f9f9;
  }

  .workerPage header {
    display: inline-flex;
    align-items: flex-start;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 35px;
    justify-content: space-between;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 35px;
  }

  .headerContainer {
    margin-left: 55px;
    margin-right: 55px;
  }

  .workerPage header h1 {
    font-size: 20px;
    letter-spacing: -0.8px;
    font-family: "DM Sans";
    font-weight: 500;
  }

  .workerPage header h3 {
    font-size: 32px;
    letter-spacing: -1.2px;
    font-family: "DM Sans";
    color: #000;
    font-weight: bold;
    line-height: 100%;
  }

  .profileText {
    color: #000;
    font-family: "DM Sans";
    font-weight: 500;
    font-size: 18px;
    text-decoration: none;
    letter-spacing: -0.8px;
  }

  .interestedPopUp {
    background-color: #fff;
    border: 1px solid #508ce4;
    font-size: 16px;
    color: #508ce4;
    padding: 10px 15px;
    border-radius: 100px;
    font-family: "DM Sans";
    letter-spacing: -0.5px;
    font-weight: 500;
    text-align: center;
  }

  .estruturaPerfil {
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }

  .perfilDoMan {
    width: 100%;
    padding-left: 15px;
    text-align: start;
    border-left: 3px dotted #508ce4;
  }

  .perfilDoMan h4 {
    font-size: 22px;
    font-family: "DM Sans";
    letter-spacing: -0.5px;
  }

  .leavingReviewZone {
    position: fixed;
    display: flex;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    z-index: 1000000;
  }

  .leavingReviewZone header {
    display: flex;
    flex-direction: column;
    width: 600px;
    padding: 35px;
    border-radius: 35px;
  }

  .leavingReviewZone header h2 {
    font-size: 36px;
    line-height: 100%;
    padding: 0;
  }

  .opcoes {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
  }

  .descricaoEstado {
    padding: 0;
    margin: 0;
    margin-top: -15px;
    font-size: 16px;
    letter-spacing: -0.5px;
    font-family: "DM Sans";
  }

  .nome {
    font-size: 14px;
    font-family: "DM Sans";
    color: #000;
    padding-top: 20px;
    margin-bottom: -5px;
  }

  .adiciona {
    font-size: 14px;
    font-family: "DM Sans";
    color: #508ce4;
  }

  .rightHeader {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 55px;
    margin-top: 20px;
  }

  .agoraDepois {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .agora,
  .depois {
    display: block;
    margin-bottom: 15px;
  }

  .depois {
    width: 100%;
    border-radius: 35px;
    padding: 25px;
    padding-top: 40px;
    background-color: #fff;
    border: 1px solid #eee;
    height: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .depois p {
    color: #000;
    font-family: "DM Sans";
    letter-spacing: -0.5px;
    text-align: center;
    font-size: 16px;
  }

  .distritoItem {
    font-family: "DM Sans";
    letter-spacing: -0.5px;
    font-weight: bold;
    font-size: 16px;
    margin-top: 15px;
    text-align: center;
  }

  .concelhosList li {
    font-size: 16px;
    font-weight: 400;
    border-radius: 100px;
    background-color: #f9f9f9;
    margin-bottom: 5px;
    padding: 15px;
  }

  .concelhosListMain {
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 100px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .xIconContainer {
    position: fixed;
    top: 95px;
    right: 55px;
    color: #fff;
    font-family: "DM Sans";
    letter-spacing: -0.8px;
    background-color: #333;
    border: 1px solid #000;
    padding: 10px 25px;
    border-radius: 100px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

  .xIconContainer:hover {
    opacity: 0.7;
  }

  .xIconContainer b {
    color: #fff;
    font-family: "DM Sans";
    letter-spacing: -0.8px;
    margin-left: 5px;
    font-size: 18px;
  }

  .concelhosList {
    width: 350px;
    height: 420px;
    background-color: #f2f2f2;
    padding: 35px;
    border-radius: 35px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    text-align: center;
  }

  button {
    padding: 10px 25px;
    background-color: #508ce4;
    color: #fff;
    font-family: "DM Sans";
    letter-spacing: -0.5px;
    border: 0;
    font-size: 18px;
    border-radius: 100px;
    margin-top: 5px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

  button:hover {
    opacity: 0.7;
  }

  .adicionarBtn {
    background-color: #508ce4;
    color: #fff;
  }

  .recusarBtn {
    border: 1px solid #8c8c8c;
    background-color: transparent;
    color: #8c8c8c;
  }

  p,
  h3 {
    font-family: "DM Sans";
  }

  .descricao {
    font-family: "DM Sans";
    letter-spacing: -0.5px;
    font-size: 16px;
    color: #333;
    margin-top: -25px;
  }

  .inputs {
    margin-top: -80px;
    padding-bottom: 30px;
  }

  .option {
    flex-direction: column;
    font-family: "DM Sans";
    color: #333;
    text-align: left;
    border-radius: 15px;
    margin-bottom: 10px;
    background-color: #f9f9f9;
    border: 1px solid #eee;
    width: 100%;
    padding-bottom: 15px;
  }
  .option:hover {
    opacity: 0.7;
    transition: 0.3s ease;
    cursor: pointer;
  }
  .option h4 {
    margin-bottom: 5px;
    font-family: "DM Sans";
    letter-spacing: -0.8px;
    font-size: 22px;
    color: #000;
    padding-left: 35px;
    margin-right: 35px;
  }
  .option p {
    font-family: "DM Sans";
    letter-spacing: -0.8px;
    font-size: 16px;
    margin-top: 0;
    padding-left: 35px;
    margin-right: 35px;
  }

  .optionSelected {
    flex-direction: column;
    font-family: "DM Sans";
    color: #333;
    text-align: left;
    border-radius: 15px;
    margin-bottom: 10px;
    background-color: #fff;
    border: 1px solid #508ce4;
    width: 100%;
    padding-bottom: 15px;
  }
  .opoptionSelectedion:hover {
    opacity: 0.7;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
  }
  .optionSelected h4 {
    margin-bottom: 5px;
    font-family: "DM Sans";
    letter-spacing: -0.8px;
    font-size: 22px;
    color: #508ce4;
    padding-left: 35px;
    margin-right: 35px;
  }
  .optionSelected p {
    font-family: "DM Sans";
    letter-spacing: -0.8px;
    font-size: 16px;
    margin-top: 0;
    color: #333;
    padding-left: 35px;
    margin-right: 35px;
  }

  .botoes {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

@media only screen and (max-width: 1480px) {
  .workerPage {
    margin-left: 25px;
    margin-right: 25px;
  }
}

@media only screen and (max-width: 950px) {
  .estruturaPerfil {
    flex-wrap: wrap;
    gap: 0px;
  }

  .workerPage header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
    padding-bottom: 0;
  }

  .rightHeader {
    margin-left: 55px;
  }

  .workerPage {
    margin-top: 90px;
  }

  .descricaoEstado {
    margin-top: -30px;
  }

  .leavingReviewZone header {
    padding-bottom: 45px;
    margin: 25px;
  }
}

@media only screen and (max-width: 500px) {
  .workerPage {
    margin-left: 15px;
    margin-right: 15px;
  }

  .workerPage header {
    margin: 0;
  }

  .rightHeader {
    display: block;
    margin: 15px;
  }

  .headerContainer {
    margin-left: 25px;
    margin-right: 25px;
  }

  .leavingReviewZone header {
    padding-bottom: 45px;
    margin: 15px;
    max-height: 450px;
    padding-top: 75px;
    overflow: auto;
  }

  .leavingReviewZone header h2 {
    padding-top: 55px;
  }
}
