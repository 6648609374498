@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

@media only screen and (max-width: 10000px) {
  .Select {
    width: "100%";
    margin-left: 100px;
    margin-right: 100px;
  }

  .Select2 {
    width: 300px;
    align-self: center;
  }

  .localizacoes {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .formDesktop {
    display: block;
  }

  .divCabecalho {
    margin-left: 250px;
    margin-right: 250px;
    margin-top: 150px;
    opacity: 1;
  }

  .title {
    font-size: 42px;
    letter-spacing: -0.5px;
    font-family: "DM Sans";
    color: #508ce4;
    margin-top: 100px;
    padding-top: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    font-weight: bold;
  }

  .subtitle {
    font-size: 24px;
    background-color: #f9f9f9;
    border: 1px solid #eee;
    padding-top: 15px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 15px;
    /* font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif; */
    font-family: "DM Sans";
    color: #333;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    border-radius: 10px;
    text-transform: uppercase;
  }

  .botoes {
    display: inline-flex;
    text-align: center;
  }

  .btnPostJob {
    background-color: #219ebc;
    color: #fff;
    border-radius: 5px;
    box-shadow: 2px 2px 10px #219ebc;
    list-style-type: none;
    width: 150px;
    padding: 1px;
    z-index: 10;
  }

  .btnPostJob h3 {
    font-size: 18px;
    font-family: "Avenir Next";
    text-align: center;
  }

  .btnRegister {
    border: 1px solid #219ebc;
    color: #219ebc;
    border-radius: 5px;
    box-shadow: 2px 2px 10px #219ebc;
    list-style-type: none;
    width: 150px;
    padding: 1px;
    z-index: 10;
  }

  .btnRegister h3 {
    font-size: 18px;
    font-family: "Avenir Next";
    text-align: center;
  }

  .btnRegister,
  .btnPostJob:hover {
    opacity: 0.75;
    transition: 0.5s;
    cursor: pointer;
  }

  .divQuestions {
    position: relative;
    text-align: center;
    font-family: "DM Sans";
    background-color: #fff;
    border: 1px solid #eee;
    margin-left: 250px;
    margin-right: 250px;
    padding-left: 105px;
    padding-right: 105px;
    padding-bottom: 55px;
    padding-top: 25px;
    border-radius: 15px;
  }

  .question h1 {
    font-size: 45px;
    padding-left: 5px;
    letter-spacing: -0.5px;
    line-height: 110%;
    font-weight: 500;
  }

  .question h1 b {
    font-size: 45px;
  }

  .subtitleSemDots {
    font-size: 150%;
    font-family: "Avenir Next";
    margin-top: -30px;
    margin-bottom: 50px;
    color: #219ebc;
    font-weight: 400;
  }

  .jobsSelect {
    width: 30%;
    height: 2rem;
    border: 2px solid black;
    margin: 5px;
    border-radius: 10px;
    font-size: 125%;
    font-family: "Avenir Next";
    font-weight: 200;
  }
  .continueButton {
    background-color: #508ce4;
    color: #fff;
    font-size: 125%;
    font-family: "DM Sans";
    font-weight: bold;
    border: none;
    border-radius: 100px;
    padding: 10px 25px;
    margin: 5px;
    cursor: pointer;
  }

  .anteriorBtn {
    background-color: transparent;
    color: #508ce4;
    font-size: 125%;
    font-family: "DM Sans";
    font-weight: bold;
    border: none;
    border-radius: 100px;
    padding: 10px 25px;
    margin: 5px;
    margin-left: 25px;
    cursor: pointer;
  }
  .question {
    display: block;
    margin: auto;
    margin-left: 20px;
  }
  .displayNone {
    display: none;
  }

  .botoesSelect {
    margin-left: 100px;
    margin-right: 100px;
  }
  .categoryLabel {
    display: flex;
    font-size: 18px;
    font-family: "DM Sans";
    color: #8c8c8c;
    align-items: center;
    justify-content: center;
    margin: 15px;
    padding: 15px;
    text-align: center;
    border-radius: 100px;
    letter-spacing: -0.5px;
    background-color: #f4f4f4;
  }
  .categoryLabel h4,
  .categoryLabelSelected h4 {
    margin: 0;
  }

  .categoryLabel:hover {
    opacity: 0.7;
    transition: 0.3s ease;
    cursor: pointer;
  }
  .categoryLabelSelected {
    display: flex;
    font-size: 18px;
    font-family: "DM Sans";
    letter-spacing: -0.5px;
    color: #fff;
    align-items: center;
    justify-content: center;
    margin: 15px;
    padding: 20px;
    text-align: center;
    border-radius: 100px;
    background-color: #508ce4;
  }

  textarea {
    resize: none;
    outline: none;
    padding: 20px;
    padding-top: 25px;
    padding-bottom: 25px;
    font-family: "DM Sans";
    border-radius: 5px;
    border: 1px solid #e8e8e9;
    font-size: 22px;
    letter-spacing: -0.5px;
  }

  .textareaSmall {
    resize: none;
    outline: none;
    font-family: "DM Sans";
    border-radius: 5px;
    border: 1px solid #e8e8e9;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.5px;
    border-radius: 100px;
  }

  input {
    font-family: "DM Sans";
    resize: none;
    padding: 15px;
    font-family: "DM Sans";
    border-radius: 5px;
    border: 1px solid #e8e8e9;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.5px;
    border-radius: 100px;
  }

  .postJobZero {
    display: flex;
    justify-content: center;
    margin-left: 1f5px;
  }

  /* .postJobUm,
  .postJobZero,
  .postJobDois {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .postJobUm div,
  .postJobDois div {
    width: 30%;
  }
  .postJobUm div .Select {
    width: fit-content;
  }
  .postJobUm div input,
  .postJobDois div input {
    width: 100%;
    padding: 15px;
    border: 1px solid #508ce4;
    border-radius: 5px;
  } */

  .postJobUm,
  .postJobDois {
    display: flex;
    justify-content: center;
    gap: 15px;
  }

  .postJobUm input,
  .postJobDois input {
    width: 300px;
  }

  .passContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .togglePasswordButton {
    width: 25px;
    height: 25px;
    padding: 0px;
    background-color: transparent;
    border: 0;
    margin: 0;
    margin-left: -35px;
  }

  .togglePasswordButton img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: invert(1);
  }

  #Continuarbtn {
    background-color: #508ce4;
    color: #fff;
    font-size: 125%;
    font-family: "DM Sans";
    letter-spacing: -0.5px;
    font-weight: bold;
    border: none;
    border-radius: 100px;
    width: 150px;
    cursor: pointer;
  }
  .containerTudoCheckBoxes {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .containerCheckBoxes {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-left: 35px;
    margin-bottom: 20px;
    margin-top: 15px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .containerCheckBoxes input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    left: 0;
    height: 25px;
    border-radius: 5px;
    width: 25px;
    background-color: #8c8c8c;
  }

  .containerCheckBoxes:hover input ~ .checkmark {
    background-color: #8c8c8c;
  }

  .containerCheckBoxes input:checked ~ .checkmark {
    background-color: #508ce4;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .containerCheckBoxes input:checked ~ .checkmark:after {
    display: block;
  }

  .containerCheckBoxes .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .formMobile {
    display: none;
  }
}

@media only screen and (max-width: 1400px) {
  .formDesktop {
    display: none;
  }

  .formMobile {
    display: block;
  }

  .togglePasswordButton {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 1250px) {
  .divCabecalho {
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 150px;
    opacity: 1;
  }

  .divQuestions {
    position: relative;
    text-align: center;
    font-family: "DM Sans";
    background-color: #fff;
    z-index: 10;
    margin-left: 25px;
    margin-right: 25px;
    padding: 5px 50px 50px 20px;
    margin-top: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .Select {
    width: "100%";
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 550px) {
  .divQuestions {
    margin-left: 15px;
    margin-right: 15px;
    padding: 0;
    padding-top: 25px;
    padding-bottom: 25px;
    margin: 15px;
    width: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .localizacoes {
    flex-direction: column;
    gap: 5px;
  }

  .divCabecalho {
    margin: 95px 15px 15px 15px;
  }

  .categoryLabel {
    font-size: 16px;
  }

  .categoryLabelSelected {
    font-size: 16px;
  }

  .question h1 {
    font-size: 30px;
    padding-left: 5px;
    letter-spacing: -0.5px;
    line-height: 110%;
    font-weight: 500;
    margin-bottom: 25px;
  }

  .question h1 b {
    font-size: 30px;
  }

  textarea {
    min-height: 200px;
  }

  .title {
    font-size: 160%;
    font-family: "DM Sans";
    background-color: #508ce4;
    margin-top: 100px;
    padding-top: 15px;
    padding-bottom: 20px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: #fff;
    text-align: center;
    font-weight: bold;
  }

  .question {
    width: auto;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px;
  }

  textarea {
    font-size: 16px;
  }
}

@media only screen and (max-width: 475px) {
  .continueButton {
    padding: 10px 20px;
    font-size: 16px;
    font-family: "DM Sans";
    letter-spacing: -0.5px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .anteriorBtn {
    font-size: 16px;
  }
}

@media only screen and (max-width: 390px) {
  .continueButton {
    margin-left: 25px;
  }
}
