@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

@media only screen and (max-width: 10000px) {
  .confirmationbanner {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 300;
    background-color: rgba(20, 20, 20, 0.25);
    backdrop-filter: blur(5px);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .confirmationcard {
    background-color: #fff;
    max-width: 500px;
    text-align: center;
    padding: 35px;
    padding-top: 55px;
    border-radius: 35px;
    font-size: 18px;
    font-family: "DM Sans";
    letter-spacing: -0.8px;
    font-weight: 500;
    margin: 25px;
  }
  .confirmationbuttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
    gap: 25px;
  }
  .confirmationbuttons button {
    font-size: 20px;
    cursor: pointer;
  }
  .confirmationyes,
  .confirmationno {
    color: white;
    padding: 10px 25px;
    border-radius: 100px;
    border: 0;
    font-family: "DM Sans";
    letter-spacing: -0.5px;
    width: 100%;
  }

  .confirmationyes {
    background-color: #508ce4;
  }
  .postTrabalho {
    background-color: #fff;
    margin-left: 250px;
    margin-right: 225px;
    margin-top: 120px;
    padding: 5px;
    border-radius: 5px;
    display: flex;
  }

  .postTrabalhoGeral {
    display: flex;
    align-items: flex-start;
    gap: 25px;
    width: 100%;
  }

  .criticasConvites {
    width: 100%;
  }

  .criticas,
  .convites {
    padding: 25px;
    border-radius: 35px;
    background-color: #f9f9f9;
    border: 1px solid #eee;
  }

  .criticas h3,
  .convites h3 {
    font-family: "DM Sans";
    letter-spacing: -1.2px;
    font-size: 32px;
    line-height: 100%;
    margin-top: 15px;
  }

  .convites h5,
  .criticas h5 {
    font-family: "DM Sans";
    letter-spacing: -0.5px;
    font-size: 16px;
    line-height: 100%;
    margin-top: 15px;
    color: #508ce4;
    background-color: transparent;
    border: 1px solid #508ce4;
    border-radius: 100px;
    padding: 10px 25px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .criticas p,
  .convites p {
    font-size: 18px;
    font-family: "DM Sans";
    color: #000;
    letter-spacing: -0.5px;
    font-weight: 500;
  }

  .convitesTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
  }

  .btnConvite {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #508ce4;
    color: #fff;
    font-family: "DM Sans";
    letter-spacing: -0.5px;
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 100px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

  .btnConvite:hover {
    opacity: 0.7;
  }

  .infoTrabalho {
    background-color: #f9f9f9;
    padding: 25px;
    border-radius: 35px;
    border: 1px solid #eee;
  }

  .infoTrabalhoMobile {
    display: none;
  }

  .infoTrabalho table,
  .infoTrabalhoMobile table {
    width: 100%;
    border: 0;
  }

  .infoTrabalho table th,
  td {
    border: 0;
    align-items: center;
    text-align: start;
    justify-content: center;
    font-family: "DM Sans";
    letter-spacing: -0.8px;
    font-size: 18px;
  }

  .infoTrabalhoMobile table th,
  td {
    border: 0;
  }

  .infoTrabalhoMobile table th {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    font-family: "DM Sans";
    letter-spacing: -0.8px;
    font-size: 16px;
  }

  .infoTrabalhoMobile table td {
    width: 100%;
    padding: 0;
    padding-left: 75px;
    font-size: 16px;
  }

  .infoTrabalho table img,
  .infoTrabalhoMobile table img {
    margin-right: 10px;
  }

  .infoTextos {
    margin-left: 20px;
    font-weight: 600;
    font-family: "DM Sans";
  }

  .headerList {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: -10px;
  }

  .headerList p {
    font-size: 18px;
    letter-spacing: -0.5px;
  }

  .infoInfo {
    margin-top: 5px;
    margin-left: 50px;
    margin-right: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    font-family: "DM Sans";
    line-height: 115%;
  }

  .infoNome {
    color: #508ce4;
    font-weight: 500;
    text-align: center;
    font-family: "DM Sans";
    font-size: 18px;
    letter-spacing: -0.5px;
  }

  .interessados {
    font-family: "DM Sans";
    font-weight: 400;
    margin-top: 5px;
  }

  .botoes {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .showInterestBtn,
  .removeInterestBtn {
    background-color: #508ce4;
    color: #fff;
    font-size: 18px;
    font-family: "DM Sans";
    letter-spacing: -0.5px;
    border-radius: 100px;
    padding: 10px 25px;
    border: 0;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

  .removeInterestBtn {
    background-color: #f00;
  }

  .showInterestBtn:hover {
    opacity: 0.7;
  }

  .descricoesTrabalho {
    margin-top: 35px;
    padding: 35px;
    border-radius: 35px;
    background-color: #f9f9f9;
    border: 1px solid #eee;
    width: 100%;
  }

  .descricoesTrabalhoImagens {
    margin-top: 35px;
    padding: 35px;
    border-radius: 35px;
    background-color: #f9f9f9;
    border: 1px solid #eee;
  }

  .descricoesTrabalho header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .descricoesTrabalhoMain {
    display: flex;
    gap: 25px;
  }

  .descricoesTrabalhoZones {
    display: flex;
    align-items: flex-start;
    gap: 25px;
  }

  .descricoesTrabalhoZonesFilho {
    display: flex;
    flex-direction: column;
    gap: 0;
    margin-top: -22.5px;
  }

  .descricoesTrabalhoZonesFilho p {
    margin-top: -10px;
    font-family: "DM Sans";
    letter-spacing: -0.5px;
    font-size: 16px;
    padding-top: 20px;
    border-top: 1px solid #ccc;
  }

  .descricoesTrabalhoZonesFilho h3,
  .descricoesTrabalhoImagens h3 {
    letter-spacing: -0.5px;
    font-family: "DM Sans";
    font-size: 26px;
  }

  .descricoesTrabalho h2 {
    font-size: 28px;
    font-family: "DM Sans";
    letter-spacing: -1.2px;
  }

  .descricoesTrabalho h3 {
    font-family: "DM Sans";
    letter-spacing: -0.5px;
    font-size: 22px;
  }

  .postImagens {
    display: flex;
    flex-wrap: wrap;
  }

  .postImagesScroll {
    display: flex;
    gap: 15px;
    overflow: auto;
    padding-bottom: 20px;
  }

  .postImagens img,
  .postImagens input {
    width: 100px;
    height: 100px;
  }

  .postImagens button {
    background-color: #508ce444;
    color: #fff;
    font-size: 50px;
    font-weight: 300;
    font-family: "DM Sans";
    width: 150px;
    height: 150px;
    margin-top: 0px;
    border-radius: 15px;
    cursor: pointer;
  }

  .imageContainer {
    width: 150px;
    height: 150px;
    background-color: #333;
    border-radius: 15px;
  }

  .imageContainer img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    object-fit: contain;
  }
}

@media only screen and (max-width: 1680px) {
  .postTrabalho {
    margin-left: 15px;
    margin-right: 15px;
    display: block;
  }
}

@media only screen and (max-width: 1480px) {
  .postTrabalhoGeral {
    flex-direction: column;
  }
}

@media only screen and (max-width: 875px) {
  .infoTrabalho {
    display: none;
  }

  .infoTrabalhoMobile {
    background-color: #f9f9f9;
    padding: 25px;
    border-radius: 35px;
    border: 1px solid #eee;
    display: flex;
    flex-direction: column;
  }

  .descricoesTrabalhoMain {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  .descricoesTrabalho {
    width: auto;
  }

  .postImagens {
    width: 100%;
    overflow: hidden;
  }

  .postImagesScroll {
    display: flex;
    overflow: auto;
    padding-bottom: 20px;
  }

  .infoTextos {
    margin-left: 10px;
    font-weight: 600;
    font-size: 13px;
    font-family: "DM Sans";
  }

  .infoInfo {
    margin-left: 10px;
    font-family: "DM Sans";
    font-size: 13px;
  }

  .botoes {
    justify-content: center;
  }
}

@media only screen and (max-width: 475px) {
  .postTrabalho {
    margin-top: 95px;
  }

  .infoTrabalhoMobile {
    padding: 15px;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .infoTrabalhoMobile table th {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    font-family: "DM Sans";
    letter-spacing: -0.8px;
    font-size: 16px;
  }

  .infoTrabalhoMobile table td {
    width: 100%;
    padding: 0;
    padding-left: 35px;
    font-size: 16px;
  }

  .infoTrabalhoMobile table img {
    margin-right: 5px;
    margin-top: 2.5px;
  }

  .confirmationcard {
    max-width: 270px;
    padding: 25px;
    padding-top: 45px;
    margin: 25px;
  }

  .confirmationbuttons {
    justify-content: center;
    flex-direction: column;
    gap: 0px;
  }

  .confirmationyes,
  .confirmationno {
    padding: 10px;
  }
}
