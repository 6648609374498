@font-face {
  font-family: "Raleway";
  src: url("../fonts/Raleway/static/Raleway-Regular.ttf") format("truetype"); /* Adjust the path accordingly */
  /* You can include other font weights and styles as well if needed */
}
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

@media only screen and (max-width: 10000px) {
  .divCabecalho {
    margin: 0 200px 0px 200px;
    background-color: #fff;
    padding-left: 50px;
    padding-top: 150px;
    padding-bottom: 50px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .divCabecalho img {
    border-bottom-right-radius: 0px;
    height: 130%;
  }

  .workers_side {
    width: 450px;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -15px;
  }

  .imgWorkers {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .title {
    font-size: 62px;
    font-family: "DM Sans";
    line-height: 100%;
    letter-spacing: -0.5px;
    color: #000;
    font-weight: 500;
  }

  .title b {
    font-size: 68px;
    letter-spacing: -1.5px;
    font-weight: 600;
  }

  .subtitle {
    font-size: 120%;
    padding-bottom: 30px;
    margin-top: -10px;
    text-decoration: underline;
    text-decoration-color: rgba(0, 61, 250, 0.05);
    text-underline-offset: 12px;
    text-decoration-thickness: 10px;
    /* font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif; */
    font-family: "Avenir Next";
    width: 70%;
    color: #666;
    font-weight: 500;
    font-family: "DM Sans";
  }

  .botoes {
    display: inline-flex;
    margin-bottom: 50px;
  }

  .btnPostJob {
    background-color: #508ce4;
    color: #fff;
    border-radius: 100px;
    list-style-type: none;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 2px;
    padding-bottom: 2px;
    z-index: 10;
    margin-left: 20px;
  }

  .btnPostJob h3 {
    font-size: 18px;
    font-family: "Raleway";
    text-align: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
  }

  .btnRegister {
    color: #508ce4;
    border-radius: 100px;
    background-color: #fff;
    border: 1px solid #508ce4;
    padding: 0px 25px;
    list-style-type: none;
    z-index: 10;
    font-size: 16px;
    text-align: center;
    font-family: "DM Sans";
    letter-spacing: -0.5px;
  }

  .btnRegister:hover,
  .btnPostJob:hover {
    opacity: 0.5;
    transition: 0.3s;
    cursor: pointer;
  }

  .divAboutUs,
  .divAboutUsDesc {
    margin-left: 250px;
    margin-right: 200px;
    padding-bottom: 50px;
    padding-top: 50px;
    z-index: 10;
    border-radius: 5px;
    margin-top: 50px;
    text-align: start;
    font-size: 18px;
    font-family: "Raleway";
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .divAboutUsDesc {
    gap: 35px;
  }

  .divAboutUsFilho {
    text-align: start;
    width: 27%;
    padding: 35px;
    height: 180px;
    background-color: #fff;
    border-radius: 15px;
    margin-top: 25px;
  }

  .divAboutUsFilho header {
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }

  .numberPasso {
    font-size: 28px;
  }

  .divAboutUs h1,
  .divAboutUsDesc h1 {
    font-size: 46px;
    line-height: 105%;
    letter-spacing: -0.5px;
    color: #508ce4;
    font-family: "DM Sans";
    text-align: start;
  }

  .divAboutUs h5,
  .divAboutUsDesc h5 {
    font-size: 22px;
    line-height: 105%;
    letter-spacing: -0.5px;
    color: #000;
    font-family: "DM Sans";
    text-align: start;
  }

  .divAboutUs div p,
  .divAboutUsDesc div p {
    font-size: 1rem;
    margin-top: -30px;
    padding: 0;
    text-align: start;
    font-family: "DM Sans";
    color: #000;
  }

  .titles {
    margin-left: 250px;
    text-align: start;
    color: #111;
    font-size: 52px;
    font-family: "DM Sans";
    letter-spacing: -1.2px;
    margin-top: 10px;
    padding-top: 70px;
    margin-bottom: -50px;
    font-weight: 500;
    line-height: 105%;
  }

  .titles b {
    font-size: 69px;
  }

  .zonaComecar {
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    padding-bottom: 75px;
  }

  .verComoFuncionaBtn {
    text-align: center;
    background-color: #000;
    color: #fff;
    border: 1px solid #000;
    width: 220px;
    border-radius: 100px;
    padding-top: 200px;
    font-family: "DM Sans";
    font-size: 18px;
    letter-spacing: -0.5px;
    margin-left: 250px;
    cursor: pointer;
    transition: 0.3s;
    padding: 20px;
  }

  .verComoFuncionaBtn:hover {
    opacity: 0.5;
  }

  .subtitleSemDots {
    font-size: 18px;
    font-family: "Raleway";
    color: #508ce4;
    font-weight: 400;
  }

  .zonaApply {
    margin-left: 200px;
    margin-right: 200px;
  }

  .zonaApplyEsq {
    width: 50%;
  }

  .zonaApplyDir {
    width: 50%;
  }

  .divCabecalho2 {
    margin: 0 200px 0px 200px;
    background-color: #fff;
    padding-left: 50px;
    padding-top: 10px;
    border-bottom-right-radius: 15px;
    opacity: 1;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
  }

  .workers_side2 {
    width: 600px;
    height: 350px;
    border-radius: 5px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .workers_side2 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .workers_side2 p {
    color: #000;
  }

  .btnApply {
    background-color: #508ce4;
    color: #fff;
    border-radius: 100px;
    list-style-type: none;
    padding-left: 10px;
    padding-right: 10px;
    width: 250px;
    padding-top: 2px;
    padding-bottom: 2px;
    z-index: 10;
    transition: all 0.2s ease-in-out;
  }

  .btnApply:hover {
    opacity: 0.7;
    transform: 0.3s ease;
  }

  .btnApply h3 {
    font-size: 18px;
    font-family: "DM Sans";
    text-align: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
  }

  .backGroundTheme {
    display: none;
  }

  .verComoFuncionaBtn {
    text-align: center;
    background-color: #508ce4;
  }

  .verComoFuncionaBtn h1 {
    background-color: #508ce4;
    color: #fff;
    font-size: 180%;
    padding-top: 30px;
    font-family: "Raleway";
  }

  .verComoFuncionaBtn p {
    background-color: #508ce4;
    color: #fff;
    font-size: 16px;
    font-family: "Avenir Next";
    margin-left: 25rem;
    padding-bottom: 30px;
    margin-right: 25rem;
  }
}

@media screen and (max-width: 1750px) {
  .workers_side {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 20px;
  }

  .imgWorkers {
    border-bottom-right-radius: 15px;
    width: 500px;
  }
}

@media screen and (max-width: 1600px) {
  .workers_side {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 20px;
  }

  .divAboutUsFilho {
    width: 26%;
    height: 200px;
  }

  .imgWorkers {
    border-bottom-right-radius: 15px;
    width: 400px;
  }
}

@media screen and (max-width: 1420px) {
  .divCabecalho {
    margin: 0 0px 0px 0px;
    background-color: #fff;
    box-shadow: 0px -50px 25px rgba(0, 0, 0, 0.1);
    padding-left: 50px;
    padding-top: 120px;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .divAboutUsFilho {
    width: 27%;
    height: 160px;
  }

  .titles {
    margin-left: 50px;
  }

  .divAboutUs,
  .divAboutUsDesc {
    margin-left: 50px;
    margin-right: 50px;
  }

  .verComoFuncionaBtn {
    margin-left: 50px;
  }
}

@media only screen and (max-width: 1250px) {
  .divAboutUsFilho {
    width: 25%;
    height: 230px;
  }
}

@media screen and (max-width: 1000px) {
  .imgWorkers {
    width: 400px;
  }

  .workers_side2,
  .workers_side {
    display: none;
  }

  .divAboutUs,
  .divAboutUsDesc {
    flex-direction: column;
  }

  .titles {
    margin-left: 15px;
  }

  .zonaComecar {
    padding-left: 35px;
    padding-right: 35px;
  }

  .divAboutUsFilho {
    width: 100%;
    height: 150px;
  }

  .verComoFuncionaBtn {
    margin-left: 15px;
  }
}

@media screen and (max-width: 600px) {
  .divCabecalho,
  .divCabecalho2 {
    flex-direction: column;
  }

  .workers_side2,
  .workers_side {
    display: flex;
    margin-top: -15px;
    margin-left: -40px;
  }

  .workers_side2 {
    width: 400px;
    height: 300px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    margin-left: -50px;
  }

  .botoes {
    flex-direction: column;
    margin-right: 25px;
    margin-left: 0;
    padding-left: 0px;
    width: 80vw;
    gap: 15px;
  }

  .btnPostJob {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 515px) {
  .title,
  .titles {
    font-size: 52px;
  }

  .title b,
  .titles b {
    font-size: 60px;
  }

  .divAboutUsFilho {
    height: auto;
  }

  .subtitle {
    margin-right: 15px;
    width: auto;
  }
}

@media only screen and (max-width: 475px) {
  .title {
    font-size: 46px;
  }

  .title b {
    font-size: 53px;
  }

  .workers_side {
    width: 400px;
    margin-left: 0;
  }

  .divCabecalho {
    padding-left: 20px;
    padding-right: 0;
  }
  .titles {
    margin-left: -10px;
  }

  .botoes {
    width: 89vw;
    margin-right: auto;
  }

  .divAboutUs {
    margin: 25px;
  }

  .divAboutUsDesc {
    margin: 0;
    margin-top: -55px;
    gap: 15px;
  }

  .verComoFuncionaBtn {
    margin-right: 0;
    margin-left: 0;
    margin-top: -25px;
  }

  .btnApply {
    width: auto;
    padding-right: 0;
    padding-left: 0;
    margin-right: 25px;
    margin-bottom: 35px;
  }

  .espreitarBreak {
    margin-top: -55px;
  }

  .workers_side {
    height: 250px;
    margin-bottom: 75px;
  }

  .workers_side2 {
    width: 100%;
    margin: auto;
    margin-left: -10px;
  }

  .imgWorkers {
    width: 320px;
    margin: auto;
  }
}

@media only screen and (max-width: 405px) {
  .workers_side {
    width: 250px;
  }

  .title {
    font-size: 45px;
    width: 90%;
  }

  .title b {
    font-size: 52px;
  }
}
