@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

@media only screen and (max-width: 10000px) {
  .detalhesContainer {
    background-color: #fafafa;
    margin-left: 35%;
    padding: 25px;
    padding-top: 5px;
    margin-top: 120px;
    margin-right: 250px;
    border-radius: 10px;
  }

  .distritosZone {
    display: flex;
    gap: 5px;
    margin-bottom: 15px;
    overflow: auto;
  }

  .distritosZone::-webkit-scrollbar {
    display: none;
  }

  .distritosZone a {
    padding: 10px 20px;
    background-color: #508ce4;
    border: 0;
    color: #fff;
    font-family: "DM Sans";
    letter-spacing: -0.2px;
    border-radius: 100px;
    font-size: 14px;
    justify-content: flex-end;
    align-content: flex-end;
    margin-top: 10px;
    white-space: nowrap;
    cursor: pointer;
  }

  .cardJob {
    padding: 25px;
    padding-bottom: 0;
    background-color: #fff;
    border-radius: 35px;
    border: 0.5px solid #eee;
    margin-bottom: 10px;
  }

  .detalhesContainer h1 {
    font-family: "DM Sans";
    letter-spacing: -0.8px;
  }

  .detalhesContainer header {
    display: flex;
    gap: 15px;
    margin-top: 20px;
  }

  .header {
    display: flex;
    flex-wrap: wrap;
  }

  .modalOverlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(15px);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10000;
  }

  .modalOverlay header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }

  .modalContent {
    border: 1px solid #eee;
    background-color: rgba(255, 255, 255, 0.75);
    padding: 25px;
    border-radius: 15px;
    margin-left: 15px;
    margin-right: 15px;
    width: 400px;
  }

  .modalOverlay header h2 {
    color: #000;
    text-align: left;
    font-size: 32px;
    line-height: 100%;
    font-family: "DM Sans";
    letter-spacing: -1.2px;
    font-weight: bold;
    padding: 0;
  }

  .modalOverlay header h2 b {
    color: #000;
    text-align: left;
    font-size: 38px;
    font-family: "DM Sans";
    letter-spacing: -1.2px;
    font-weight: bold;
    padding: 0;
  }

  .modalOverlay header p {
    color: #8c8c8c;
    text-align: left;
    font-size: 16px;
    margin-top: -10px;
    font-family: "DM Sans";
    letter-spacing: -0.8px;
    font-weight: 500;
  }

  .closeButton {
    position: fixed;
    top: 0;
    left: 0;
    margin-top: 45px;
    padding-left: 25px;
    padding-right: 15px;
    background-color: transparent;
    color: #fff;
    scale: 1.25;
  }

  .closeButton p {
    font-size: 26px;
  }

  .closeButton:hover,
  .paymentButton:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  .paymentOptions {
    display: flex;
  }

  .paymentButton,
  .confirmButton {
    background-color: #333;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    padding: 15px 15px;
    width: 100%;
    font-weight: bold;
    font-family: "DM Sans";
    letter-spacing: -0.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .table {
    font-family: "DM Sans";
    margin-top: 10px;
    width: 100%;
  }

  .table th {
    padding: 10px;
    background-color: #508be49c;
    text-align: start;
    color: #fff;
  }

  .creditPack {
    background-color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 15px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
    min-width: 385px;
    height: 170px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transform: rotateY(15deg);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  }

  .creditPack b {
    font-family: "DM Sans";
    letter-spacing: -0.8px;
    font-size: 22px;
    padding-left: 15px;
  }

  .price {
    font-family: "DM Sans";
    letter-spacing: -0.5px;
    font-weight: 300;
    font-size: 18px;
    margin-top: 0px;
    padding: 15px;
    background-color: #333;
    color: #fff;
  }

  .buyButton {
    background-color: #508ce4;
    border-radius: 100px;
    color: #fff;
    font-family: "DM Sans";
    letter-spacing: -0.5px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    padding: 10px 15px;
    margin-right: 10px;
  }

  .buyButton:hover {
    opacity: 0.8;
  }

  input {
    background-color: transparent;
    border: 1px solid #d6d4d4;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }

  input:focus {
    outline: none;
  }

  .botoes {
    display: inline-block;
    display: flex;
    justify-content: flex-end;
  }

  label {
    font-size: 14px;
    font-family: "DM Sans";
    letter-spacing: -0.5px;
    color: #8c8c8c;
  }

  .input {
    background-color: #f2f2f2;
    border: 0;
    border-radius: 100px;
    font-family: "DM Sans";
    font-size: 16px;
    width: 100%;
    padding: 15px 0;
  }

  .guardar {
    padding: 15px 25px;
    background-color: #508ce4;
    border: 0;
    color: #fff;
    border-radius: 100px;
    font-size: 16px;
    justify-content: flex-end;
    margin-top: 10px;
  }

  .guardar:hover,
  .terminarSessao:hover {
    cursor: pointer;
    opacity: 0.7;
    transition: 0.5s ease;
  }

  .terminarSessao {
    padding: 15px 25px;
    background-color: #f00;
    font-family: "DM Sans";
    letter-spacing: -0.5px;
    border: 0;
    color: #fff;
    border-radius: 100px;
    font-size: 16px;
    margin-top: 10px;
  }

  .headerDateTitle {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .headerDateTitle h1 {
    font-family: "DM Sans";
    letter-spacing: -1.2px;
    font-size: 22px;
    color: #000;
    font-weight: 600;
  }

  .inviteWorkersContainer {
    padding: 25px;
    background-color: #f9f9f9;
    border: 1px solid #eee;
    border-radius: 35px;
    margin-top: 25px;
  }

  .inviteWorkersContainer h3 {
    font-family: "DM Sans";
    letter-spacing: -0.5px;
    color: #000;
  }

  .inviteWorkersContainer p {
    color: #333;
    font-family: "DM Sans";
    letter-spacing: -0.5px;
    font-size: 16px;
  }

  .headerJob {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: -10px;
  }

  .headerJob p {
    color: #000;
    font-weight: 400;
    font-family: "DM Sans";
    letter-spacing: -0.5px;
    font-size: 16px;
  }

  .headerList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
  }

  .inviteBtnContainer {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .inviteBtn {
    padding: 10px 15px;
    border-radius: 100px;
    color: #fff;
    letter-spacing: -0.5px;
    font-size: 16px;
    font-family: "DM Sans";
    background-color: #508ce4;
    text-decoration: none;
    border: 0;
  }

  .addCardBtn {
    background-color: #508ce4;
    color: #fff;
    margin-top: 25px;
    margin-bottom: 25px;
    cursor: pointer;
  }

  .addCardBtn:hover {
    background-color: #508be49c;
  }

  .eliCardBtn {
    color: black;
    cursor: pointer;
  }

  .eliCardBtn:hover {
    color: #fff;
    background-color: #508ce4;
  }

  .listPay {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 15px;
    border: 3px solid #ffffff;
    padding: 10px;
    cursor: pointer;
  }

  .listPay:hover {
    border: 3px solid #8b8b8b;
  }

  .listPaySelected {
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 15px;
    border: 3px solid #508ce4;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.cardNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.addCardWindow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 201;
  background-color: #8b8b8b7f;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addCardWindow .form {
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media only screen and (max-width: 1480px) {
  .detalhesContainer {
    background-color: #fafafa;
    padding: 20px;
    margin-left: 25%;
    margin-right: 20px;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 1150px) {
  .detalhesContainer {
    background-color: #fafafa;
    padding: 20px;
    margin-top: 20px;
    margin-left: 25px;
    margin-right: 25px;
    border-radius: 10px;
  }

  .table {
    font-family: "DM Sans";
    margin-top: 10px;
    overflow: auto;
  }
}

@media only screen and (max-width: 900px) {
  .detalhesContainer header,
  .tableParent {
    overflow: auto;
    width: 100%;
  }

  .detalhesContainer header::-webkit-scrollbar,
  .tableParent::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (max-width: 475px) {
  .modalContent {
    margin-top: -75px;
    width: auto;
  }

  .paymentOptions {
    flex-direction: column;
  }

  .headerList {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .detalhesContainer {
    background-color: #fafafa;
    padding: 20px;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 10px;
  }

  .inviteBtnContainer {
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  .inviteBtn {
    text-align: center;
    width: 100%;
  }
}

@media only screen and (max-width: 450px) {
  .headerDateTitle {
    display: block;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .creditPack {
    min-width: 100%;
    height: 150px;
  }

  .price {
    margin-top: 10px;
  }
}
