@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

@media only screen and (max-width: 10000px) {
  .footer {
    position: relative;
    bottom: 0px;
    padding-bottom: 50px;
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    background-color: #f9f9f9;
    margin-top: 150px;
  }

  .footer_p {
    color: #000;
    margin-left: 200px;
    font-family: "DM Sans";
  }

  .footerBelow {
    width: 100%;
    padding-top: 70px;
    padding-bottom: 0px;
    z-index: 10;
  }

  .footerBelow_header {
    display: flex;
    color: #000;
    gap: 5rem;
    margin-left: 260px;
    margin-right: 250px;
    justify-content: space;
  }

  .footerBelow_header_h1 {
    margin-bottom: 1.6rem;
    font-family: "DM Sans";
    font-size: 19.2px;
    letter-spacing: -0.5px;
  }

  .footerBelow_header_a {
    margin-bottom: 1rem;
    font-family: "DM Sans";
    font-size: 13px;
    display: block;
    text-decoration: none;
    color: #111;
    font-weight: 500;
  }

  .footerBelow_header_a:hover {
    margin-bottom: 1rem;
    font-family: "DM Sans";
    text-decoration: underline;
  }

  .footerBelow_header_a img {
    width: 25px;
    height: 25px;
  }

  .redes {
    display: flex;
    align-items: center;
    gap: 15px;
  }
}

@media only screen and (max-width: 1480px) {
  .footer {
    position: relative;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-top: 150px;
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .footer_p {
    font-family: "DM Sans";
    margin-left: 4rem;
  }

  .footerBelow {
    width: 100%;
    padding-top: 70px;
    padding-bottom: 0px;
    z-index: 10;
  }

  .footerBelow_header {
    display: flex;
    gap: 5rem;
    margin-left: 4rem;
    margin-right: 4rem;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 1050px) {
  .footer_p {
    font-family: "DM Sans";
    text-align: center;
    margin-left: 0;
    margin-bottom: 50px;
  }

  .footerBelow_header {
    display: block;
    text-align: center;
    margin-left: 3rem;
    margin-right: 3rem;
    justify-content: flex-start;
    margin-top: -5rem;
  }

  .footerBelow_header_h1 {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  .redes {
    justify-content: center;
  }
}
