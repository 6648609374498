@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

@media only screen and (max-width: 10000px) {
  .login {
    margin-left: 250px;
    background-color: #fff;
    border: 1px solid #eee;
    width: 630px;
    font-family: "DM Sans";
    margin-bottom: 50px;
    padding: 20px;
    margin-top: 150px;
    border-radius: 10px;
  }

  .togglePasswordButton {
    width: 25px;
    height: 25px;
    padding: 0px;
    background-color: transparent;
    border: 0;
    color: #fff;
    border-radius: 5px;
    font-family: "DM Sans";
    margin-left: -50px;
    margin-top: 23.5px;
    position: absolute;
  }

  .togglePasswordButton img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: invert(1);
  }

  h2 {
    font-size: 45px;
    padding-left: 5px;
    letter-spacing: -0.5px;
    line-height: 110%;
  }

  h2 b {
    font-size: 60px;
  }

  li {
    font-family: "DM Sans";
    letter-spacing: -0.5px;
    font-size: 22px;
    color: #000;
    list-style: none;
    font-weight: 600;
  }

  .input {
    background-color: #f2f2f2;
    border: 0;
    border-radius: 100px;
    font-family: "DM Sans";
    font-size: 16px;
    width: 600px;
    padding: 15px;
  }

  .btnEntrar {
    background-color: #508ce4;
    font-family: "DM Sans";
    font-size: 16px;
    letter-spacing: -0.5px;
    border-radius: 100px;
    width: 100px;
    padding: 15px;
    margin-left: 0;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }

  .trabalhadorBtn {
    color: #508ce4;
    text-decoration: none;
  }

  .trabalhadorBtn p {
    color: #508ce4;
    background-color: #f2f2f2;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
    text-align: center;
    border-radius: 100px;
    font-family: "DM Sans";
    letter-spacing: -0.5px;
  }

  .botoes {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
  }

  .description {
    margin-top: 45px;
  }

  .description header {
    display: flex;
    align-items: start;
    gap: 35px;
  }

  .description header section {
    width: 50%;
  }
}

@media only screen and (max-width: 1480px) {
  .login {
    margin-left: 25px;
    margin-right: 25px;
    display: block;
    margin: auto;
    padding: 20px;
    margin-top: 120px;
    margin-bottom: 50px;
    border-radius: 10px;
    font-family: "DM Sans";
  }

  .togglePasswordButton {
    width: 25px;
    height: 25px;
    padding: 0px;
    background-color: transparent;
    border: 0;
    color: #fff;
    border-radius: 5px;
    font-family: "DM Sans";
    left: 560px;
    right: 0;
    margin: auto;
    display: block;
    margin-top: -47.5px;
    position: absolute;
  }
}

@media only screen and (max-width: 700px) {
  .login {
    width: auto;
    margin-left: 25px;
    margin-right: 25px;
  }

  .input {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .togglePasswordButton {
    left: 70%;
  }

  .description header {
    flex-direction: column;
  }

  .description header section {
    width: 100%;
  }
}

@media only screen and (max-width: 475px) {
  .togglePasswordButton {
    left: 62%;
  }
}
