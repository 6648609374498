@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

@media only screen and (max-width: 10000px) {
  .title {
    font-family: "DM Sans";
    letter-spacing: -1.2px;
    font-size: 26px;
    margin-top: 25px;
  }

  .titleSend {
    font-family: "DM Sans";
    letter-spacing: -0.8px;
    font-size: 30px;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .categoriaConvitesDuplos {
    background-color: #fff;
    border: 1px solid #eee;
    padding: 25px;
    border-radius: 35px;
    margin-bottom: 15px;
  }

  .categoriaConvitesDuplos2 {
    background-color: #fff;
    border: 1px solid #eee;
    padding: 25px;
    border-radius: 35px;
    margin-bottom: 15px;
    width: 40vw;
  }

  .convida {
    background-color: #fff;
    margin-left: 250px;
    margin-right: 200px;
    margin-top: 120px;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .convida div {
    display: block;
    margin-right: 15px;
  }

  .convida h1 {
    font-family: "Avenir Next";
    font-size: 20px;
  }

  .convida p,
  .convida h5,
  .convida h4,
  .convida h1 {
    font-family: "Avenir Next";
  }

  .convida h5 {
    font-weight: 500;
    font-size: 14px;
  }

  .workerCard {
    background-color: #fafafa;
    border-radius: 5px;
    padding: 20px;
    width: 100%;
  }

  .cardsLado {
    background-color: #fff;
    margin-top: 25px;
    padding-left: 25px;
    border-radius: 0px;
  }

  .cardLado {
    background-color: #fafafa;
    border: 0.5px solid #eee;
    padding: 25px;
    border-radius: 35px;
    font-family: "DM Sans";
  }

  .cardLado h1 {
    letter-spacing: -0.8px;
    font-size: 26px;
    line-height: 105%;
  }

  .cardLado h5 {
    letter-spacing: -0.5px;
    color: #333;
    font-size: 16px;
  }

  .tituloCriticas header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    gap: 25px;
  }

  .tituloCriticas header button {
    background-color: #508ce4;
    font-family: "DM Sans";
    font-size: 16px;
    letter-spacing: -0.5px;
    border-radius: 100px;
    padding: 15px 25px;
    margin-left: 0;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

  .tituloCriticas header button:hover {
    opacity: 0.7;
  }

  .tituloCriticas h5 {
    color: #508ce4;
    font-family: "DM Sans";
    margin-top: 0px;
    margin-left: 5px;
    font-size: 16px;
    letter-spacing: -0.5px;
    font-weight: 400;
  }

  .local {
    font-weight: 500;
    padding: 5px;
  }

  .iconAndTitle {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }

  .iconAndTitle b {
    font-size: 18px;
    font-family: "DM Sans";
    letter-spacing: -0.5px;
  }

  .iconAndTitle p {
    font-size: 18px;
    font-family: "DM Sans";
    letter-spacing: -0.5px;
  }
}

@media only screen and (max-width: 1480px) {
  .convida {
    margin-left: 35px;
    margin-right: 0px;
  }

  .categoriaConvitesDuplos2 {
    width: 60vw;
  }
}

@media only screen and (max-width: 1050px) {
  .categoriaConvitesDuplos2 {
    width: auto;
  }

  .convida {
    margin-left: 35px;
    margin-right: 0px;
    display: block;
  }

  .cardsLado {
    width: auto;
    margin-left: 0;
    padding: 0;
    margin-top: 50px;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 700px) {
  .tituloCriticas header {
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0px;
    flex-direction: column;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 475px) {
  .convida {
    margin-left: 15px;
    margin-right: 0px;
    margin-top: 50px;
    display: block;
  }

  .categoriaConvitesDuplos2 {
    width: 75vw;
  }

  .cardsLado {
    width: 100%;
    padding-left: 5px;
    margin-top: 50px;
  }
}
