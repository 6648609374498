@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

@media only screen and (max-width: 10000px) {
  .register {
    margin-left: 250px;
    background-color: #fff;
    border: 1px solid #eee;
    width: 630px;
    font-family: "DM Sans";
    margin-bottom: 50px;
    padding: 20px;
    margin-top: 150px;
    border-radius: 10px;
  }

  .register h2 {
    font-size: 45px;
    padding-left: 5px;
    letter-spacing: -0.5px;
    line-height: 110%;
    font-weight: 500;
  }

  .register h2 b {
    font-size: 55px;
    font-weight: 600;
  }

  .register h3 {
    font-size: 32px;
  }

  .distritosZone {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
    overflow: auto;
  }

  .distritosZone::-webkit-scrollbar {
    display: none;
  }

  .distritosZone a {
    padding: 10px 20px;
    background-color: #508ce4;
    border: 0;
    color: #fff;
    font-family: "DM Sans";
    letter-spacing: -0.2px;
    border-radius: 100px;
    font-size: 14px;
    justify-content: flex-end;
    align-content: flex-end;
    margin-top: 10px;
    white-space: nowrap;
    cursor: pointer;
  }

  .togglePasswordButton {
    width: 25px;
    height: 25px;
    padding: 0px;
    background-color: transparent;
    border: 0;
    color: #fff;
    border-radius: 5px;
    font-family: "DM Sans";
    left: 850px;
    margin-top: 23.5px;
    position: absolute;
  }

  .togglePasswordButton img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: invert(1);
  }

  .select {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .containerCheckBoxes {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 20px;
    margin-top: 20px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .containerCheckBoxes input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: -3.5px;
    left: 0;
    height: 25px;
    border-radius: 5px;
    width: 25px;
    background-color: #ddd;
  }

  .containerCheckBoxes:hover input ~ .checkmark {
    background-color: #ccc;
  }

  .containerCheckBoxes input:checked ~ .checkmark {
    background-color: #508ce4;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .containerCheckBoxes input:checked ~ .checkmark:after {
    display: block;
  }

  .containerCheckBoxes .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .btnRegistar {
    background-color: #508ce4;
    width: 250px;
    color: #fff;
    border-radius: 100px;
    margin-left: 380px;
    padding: 15px;
    font-family: "DM Sans";
    font-size: 16px;
    cursor: pointer;
  }

  .input {
    background-color: #f2f2f2;
    border: 0;
    border-radius: 100px;
    font-family: "DM Sans";
    font-size: 16px;
    width: 600px;
    padding: 15px;
  }

  .maisDoqueDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 1050px) {
  .register {
    margin-left: 25px;
    margin-right: 25px;
    width: auto;
    margin-top: 120px;
  }

  .togglePasswordButton {
    width: 25px;
    height: 25px;
    padding: 0px;
    background-color: transparent;
    border: 0;
    color: #fff;
    border-radius: 5px;
    font-family: "DM Sans";
    margin-left: auto;
    right: 55px;
    margin-top: 23.5px;
    position: absolute;
  }

  .input {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .btnRegistar {
    background-color: #508ce4;
    margin: auto;
    width: 100%;
  }
}

@media only screen and (max-width: 550px) {
  .maisDoqueDiv {
    flex-direction: column;
    align-items: flex-start;
  }
}
