@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

@media only screen and (max-width: 10000px) {
  .sidebar {
    width: 19%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
    padding-top: 20px;
    margin-top: 100px;
    border-radius: 0px 10px 10px 0px;
    margin-left: 260px;
    background-color: #fff;
  }

  .sidebar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .sidebar li {
    margin-bottom: 10px;
    background-color: #fafafa;
    padding: 10px;
    border-radius: 5px;
    font-family: "DM Sans";
    border-left: 3px solid #508ce4;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.5px;
    transition: all 0.2s ease-in-out;
  }

  .sidebar li a {
    display: block;
    color: #000;
    padding: 8px 16px;
    text-decoration: none;
  }

  .sidebar li:hover {
    background-color: #ddd;
    margin-left: 5px;
  }
}

@media only screen and (max-width: 1680px) {
  .sidebar {
    width: 16%;
  }
}

@media only screen and (max-width: 1480px) {
  .sidebar {
    margin-left: 35px;
    margin-right: 0;
    width: 20%;
  }
}

@media only screen and (max-width: 1150px) {
  .sidebar {
    margin-left: 25px;
    margin-right: 25px;
    width: auto;
    height: auto;
    position: relative;
    margin-top: 120px;
    top: 0;
    left: 0;
    overflow-x: hidden;
    padding-top: 20px;
    border-radius: 0px 10px 10px 0px;
    background-color: transparent;
  }
}

@media only screen and (max-width: 475px) {
  .sidebar {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 75px;
  }
}
