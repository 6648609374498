@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

@media only screen and (max-width: 10000px) {
  .container {
    width: 600px;
    position: relative;
    left: 0;
    right: 0;
    margin-top: 120px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 20px;
    font-family: "DM Sans";
  }

  .container h2 {
    font-family: "DM Sans";
    letter-spacing: -1.2px;
    font-size: 38px;
  }

  .container h3 {
    font-family: "DM Sans";
    letter-spacing: -0.8px;
    font-size: 22px;
    font-weight: 500;
  }

  .containerSub {
    background-color: #f9f9f9;
    border-radius: 35px;
    padding: 20px;
    border: 1px solid #eee;
    margin-top: 25px;
  }

  .containerSub h3 {
    font-family: "DM Sans";
    letter-spacing: -1.2px;
    font-weight: 600;
  }

  .textAreaContainer {
    background-color: #fff;
    padding: 25px;
    border-radius: 35px;
  }

  textarea {
    resize: none;
    padding: 0px;
    border-radius: 10px;
    font-family: "DM Sans";
    border: 0;
    font-size: 18px;
    height: 150px;
    letter-spacing: -0.5px;
    width: 100%;
  }

  .headerExperience {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-left: -40px;
  }

  .botoes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    margin-top: 25px;
  }

  .customCheckbox {
    width: 15px;
    height: 15px;
    padding: 10px;
    border-radius: 100px;
    appearance: none; /* Remove estilo padrão da checkbox */
    border: 2px solid #ccc;
    cursor: pointer;
    position: relative;
    display: inline-block;
  }

  .customCheckbox:checked {
    background-color: #508ce4; /* Cor de preenchimento quando está selecionada */
    border-color: #508ce4;
  }

  .customCheckbox:checked::after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    background-color: white; /* Adiciona o símbolo de check */
  }

  .tabela {
    display: flex;
    padding-left: 35px;
  }

  .reviews {
    align-items: center;
    justify-content: center;
    margin-top: -47px;
    margin-right: 35px;
  }
}

@media only screen and (max-width: 1050px) {
  .container {
    width: auto;
    position: relative;
    left: 0;
    right: 0;
    margin-top: 120px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 20px;
    font-family: "DM Sans";
  }
}

@media only screen and (max-width: 640px) {
  .botoes {
    flex-direction: column-reverse;
  }
}
